// Set smart contract based on chain id
export const CONTRACTADDRESS = {
    1: "0xBeB73613D5300970E09E3811d3b5FCEE417B3a97",
    4: "",
};

// Set the price of your NFT
export const ALLOWLIST_PRICE_WEI = 0;

export const PUBLIC_PRICE_WEI = 0;

// Set the max amount of your NFT collection
export const MAX_AMOUNT = 6666;

// Set the max batch size per wallet 
export const MAX_BATCH_SIZE = 2;

// Set the opesnea url 
export const OPENSEA_URL = "https://opensea.io/collection/pied-piper-dao"

export const whitelistAddresses = [
    '0xdbb374e190bda54aba80556dae3573d8a7cB93C3',
    '0x69358FC4B6f111C449e9eeF917335FE1e1c3408D',
    '0x580CE7Cd22FbC59d209b4dF8A540B1136e32747A',
    '0xF8B35BbB8408A847026EBb8e042cE13Fa8d51832',
    '0x7BcDC28950DFdc88eA44f4f74B893982B9794d81',
    '0xb3A639757973AC341533344C78D457B934108c8D',
    '0xB402a282969A3B5D96197f262807DfC95b42B05A',
    '0x29e87a2fE978d915E941aa84E757D31fcCF0ff2B',
    '0x9F1a11fa3B855c2EE843095fDb48F37B50d1d5B9',
    '0xDb541f612CfD33bBA956530898599B2e2B5bbb10',
    '0x39BFc18532E88AF58CBeE7632E0Bd3e905e9f9E5',
    '0x71DF723d9cF576FB86446bD35e93716Dd663F991',
    '0x31c1136190Bc520A8434c2F4b04c62e3547116c6',
    '0x950cc9ebe1e24287f1c40fe9e06474b87c0a335d',
    '0xd808bac0cf69ba4d497039485d40303398c0eb1b',
    '0xf6c6d4e246005f93165d02e534f56c7e65204b90',
    '0xbda3e6c5201bcef18c3c9bf5b389156f8b1982de',
    '0xaa3e2458736bcbd2f9b6169fb2cf263fcc9818c5',
    '0xde7760088296566b4a11953744aa320471aaa076',
    '0xac88d8d53caf1c8f95b4084b58aeb0cc6d9e601e',
    '0x0ee4facb71b4435950193b6b95618f6d696a0b77',
    '0xda44fb0df8d01b3e0e6cd1812cd9612864de53fe',
    '0x4e1e36443353fbd4b9bc06b97ddce8f45313e3ca',
    '0xb89dfbd325e85cb9f0d9d6e6181c667e66716298',
    '0x03b45d9b18291c1be61053e379cd181e08fba9f5',
    '0xae9d9a8fe56dbe99735cfc8b92bfe5e8ca4117cc',
    '0xca0455d22979b3b5d35c2e078e2db6a5f5243c09',
    '0xe0476e71947170feb9692c2495b8975410b16ed6',
    '0x52e5ee66aa8df2c93fdb437d3b6f922b2968a232',
    '0xd0123ddd8bd2acf22193abb42525c23fa0cb4872',
    '0xa03d0e39902c20c1575e9820c00254a2c15ede9c',
    '0x1f98b1e485b96a209aa80c09ddb7005d47025cf3',
    '0x85d087fc801a2b5916a70818c671b7ec2ccde0d8',
    '0x156b89cf421deac7cbc506e2c32ae4ba659b9393',
    '0x6c37344da3e04cec30a1fe6c1fe9a5b242cb6591',
    '0x1c4a70fe577eea07331a7a5524c58fbf9c3dcebf',
    '0x9457ae89769e456da78c447c030273cdd80c5a80',
    '0x39be252917663033f92bfd7a36a30f22fcaccc1d',
    '0xcf572ef3409057cfc023b59032647c5c90da2bb8',
    '0xe66f11ac65511e1a35bcc18b3e5c244a3123d644',
    '0xdda6dc78241493aa8fc284c15cbaece5ba9d2e19',
    '0xc5b55cfcceb40b9a44a32bc0148ffebd9ef97b43',
    '0xd9f0eec6783667f70a3ad49721ee9674935c6e4d',
    '0xa082fc85898abcc5b1e1771cd4a73b9ade5bfee5',
    '0xc02d6bf72f4339ad9d8d6707d88a7cb9e93e477f',
    '0x57510af5e209a0f2f5dbccc6af7054e864f02906',
    '0xab0e194057a1d6161f0043dc4d1c31008033639e',
    '0xd4ce54a7b2835ad1a1e50e7764f267701ad528a2',
    '0x1592a15a63141a872dee1c7aef489afaa35ae6d3',
    '0x23a54659ad16c935132682b179011c2afcb33470',
    '0xaefdbb7abeb7429ac4d5f736dcd8ff71634179ed',
    '0x7d8a9ea4fdc2a5f41adb0463fb9567c1ea830619',
    '0x55d79fc6a00053bbde80305bec1be118a1a6d456',
    '0x6d3caa8b7adc30d813b2c30690bc0c99b8e8d007',
    '0x28654da0469e9d6e43b9da9de4c4181879936b0c',
    '0x7e622951463ffb9daa888d12e6807a84292fb308',
    '0xebd6fc599833ce921011fc57f0dfe5d7cde044ea',
    '0x2bc9015fa8802b79e3189fbbaa235b7434ebad3e',
    '0x7c0298dd8dad69f80f12e0eba8323b66b7730103',
    '0x469dfd6465be5188882ebc058ee1061be380bd6d',
    '0x616384ea7c86479ee61adbbc365b8d8d28a60f31',
    '0x0240583cac769bbd28d6189d40ceb34bfa23eb11',
    '0xf69013d5a001c58f48b09b7acf9cade8dae152ad',
    '0xcFAa3F610f853A5aD55ea2fA90afa08004603aC8',
    '0x8851986C82D8727F6081a65bA9740cce2581f9ff',
    '0x918d13Ea92299e7499f19462e0f6dDc9b30ADb82',
    '0x8968c4b385246722F0f90c87BaB5B35F32cA7411'
];